<section class="item__mapa-clinicas">
  <div class="wrapper">
    <div class="item__column ">

      <div class="item__column-2 ">
        <p gsAppear class="title__h2"> Nuestras <strong> cl&iacute;nicas</strong></p>
        <p gsAppear>Más de <strong>100 clínicas</strong> oftalmológicas presentes en <strong>4
          países</strong> y en las principales ciudades de España.</p>
      </div> <!--end item-column-2-->

      <div class="item__column-2 item__mapa-clinicas-imagen">
        <img ngSrc="{{cdn}}imagenes/mapa-clinicas.png"
             alt="Mapa Clinicas"
             height="528"
             width="681">
      </div> <!--end item-column-2-->

      <div class="item__column-1">
        <p class="u-text-center visible-xs u-margin-bottom-sm u-margin-top-md">
          <strong>Busca tu clínica</strong>
        </p>
        <div class="item__mapa-clinicas-buscador">
          <form [formGroup]="form"
                (ngSubmit)="onSubmit()">
            <div style="position:relative">
              <input type="text"
                     formControlName="address"
                     placeholder="INTRODUCE TU DIRECCIÓN O CP"
                     class="input-search"
                     [class.is-invalid]="isInvalid">
              <button type="submit" class="btn-orange">Buscar</button>
            </div>
          </form>

          <a (click)="findProvince('reverse')"
             class="btn-dark-blue pointer">
            Usar mi ubicación
            <img src="{{cdn}}imagenes/icons/icon-map-point-white.svg" alt="Icono">
          </a>
        </div>


        <!-- item__mapa-clinicas-buscador -->
        <div class="item__mapa-clinicas-resultado wrapper">
          <div class="item__mapa-clinicas-resultado-nav"></div>
          <div gsAppear class="item__mapa-clinicas-resultado-slider">

            <div *ngIf="provinces.length === 1">
              <div class="item__mapa-clinicas-resultado-slide">
                <div class="item__card-clinica">
                  <a href="{{ provinces[0].clinicas.length > 1 ? '/clinicas-provincia/'+provinces[0].provincia_seo : '/clinica-oftalmologica/'+provinces[0].clinicas[0].nombre_seo }}"
                     class="item__card-clinica-nombre">
                    <picture>
                      <source srcset="{{cdn}}imagenes/ciudades/{{provinces[0].codigo_area}}.webp" type="image/webp" class=""  height="119" width="302">
                    <img ngSrc="{{cdn}}imagenes/ciudades/{{provinces[0].codigo_area}}.png"
                         alt="{{provinces[0].nombre}}"
                         height="119" width="302"
                         class="item__card-clinica-imagen img-h-auto"
                         >
                      </picture>
                    <p>{{provinces[0].nombre}}</p>
                  </a>

                  <div class="item__card-clinica-listado"
                    [class.item-collapsed]="provinces[0].clinicas.length > 1">
                    <ul>
                      <li *ngFor="let clinic of provinces[0].clinicas" class="active">
                        <a routerLink="/clinica-oftalmologica/{{clinic.nombre_seo}}">
                          <strong>{{clinic.nombre}}</strong></a>
                      </li>
                    </ul>

                  </div>
                  <a *ngIf="provinces[0].clinicas.length > 1" class="btn-arrow"  #desplegableResultado (click)="toggleResultado($event,desplegableResultado)" >
                    <img src="{{cdn}}imagenes/icons/icon-arrow-down-white-bg-circle-blue.svg" alt="">
                  </a>
                  <!-- end item__card-clinica-nombre -->
                </div>
              </div>
            </div>

            <div *ngIf="provinces.length > 1" class="item__slider">
              <ngx-slick-carousel [config]="slideConfig" #slickModal="slick-carousel">

                <div ngxSlickItem *ngFor="let province of provinces, let i = index"
                     class="item__mapa-clinicas-resultado-slide ">

                  <div class="item__card-clinica">
                    <a href="{{ province.clinicas.length > 1 ? '/clinicas-provincia/'+province.provincia_seo : '/clinica-oftalmologica/'+province.clinicas[0].nombre_seo }}"
                       class="item__card-clinica-nombre">
                       <picture>
                        <source srcset="{{cdn}}imagenes/ciudades/{{province.codigo_area}}.webp" type="image/webp" class=""  height="119" width="302">
                      <img ngSrc="{{cdn}}imagenes/ciudades/{{province.codigo_area}}.png"
                           alt="{{province.nombre}}"
                           height="119" width="302"
                           class="item__card-clinica-imagen img-h-auto"
                           >
                        </picture>
                      <p>{{province.nombre}}</p>
                    </a>
                    <!-- end item__card-clinica-nombre -->

                    <div
                         class="item__card-clinica-listado"
                         [class.item-collapsed]="province.clinicas.length > 1">
                      <ul>
                        <li *ngFor="let clinic of province.clinicas" class="active">
                          <a
                            routerLink="/clinica-oftalmologica/{{clinic.nombre_seo}}"><strong>{{clinic.nombre}}</strong></a>
                        </li>
                      </ul>

                    </div>
                    <a *ngIf="province.clinicas.length > 1"
                        class="btn-arrow"  #desplegableResultado (click)="toggleResultado($event,desplegableResultado)"
                        >
                      <img src="{{cdn}}imagenes/icons/icon-arrow-down-white-bg-circle-blue.svg" alt="">
                    </a>
                  </div>

                </div>
              </ngx-slick-carousel>
            </div>

            <div *ngIf="notFound">
              <h2 class="text-center mt-5">No se encontraron resultados</h2>
            </div>
            <!--  end item__mapa-clinicas-resultado-slider -->
          </div>
          <!-- end item__mapa-clinicas-resultado -->
        </div>
      </div>
    </div> <!-- end item-column-->

  </div>
</section>
