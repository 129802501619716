import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute} from "@angular/router";
import {HttpClient} from "@angular/common/http";
import {DeviceDetectorService} from "ngx-device-detector";
import {SolicitudService} from "../../services/solicitud.service";
import {PresenceService} from "../../services/presence.service";
import {IpService} from "../../services/ip.service";
import {ISolicitud} from "../../interfaces/solicitud.interface";

import * as $ from "jquery";
import { SalesforceService } from '../../services/salesforce.service';
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'app-header-form',
  templateUrl: './header-form.component.html',
  styleUrls: ['./header-form.component.css']
})
export class HeaderFormComponent implements OnInit {
  submitted = false;
  visible = false;
  form: FormGroup;
  ip: any;
  origin: any;
  originFormCitation: any;
  solicitud: ISolicitud;
  telefono!:string;
  @Input() origenSeccion!: any;
  public cdn: string;
  constructor(private fb: FormBuilder,
              private solicitudService: SolicitudService,
              private presenceService: PresenceService,
              private ipService: IpService,
              private route: ActivatedRoute,
              private http: HttpClient,
              private deviceService: DeviceDetectorService,
              private salesforceService:SalesforceService) {
    this.solicitud = <ISolicitud>{}
    this.form = this.buildForm()
    this.cdn = environment.cdn;
  }

  ngOnInit(): void {
    this.getIp()
    this.getOrigin()
    this.getOriginFormCita()
    this.telefono = this.origenSeccion == 'microretina' || this.origenSeccion == 'otrostratamientos' ? '900 20 60 20' : '900 180 100';
  }


  private buildForm(): FormGroup {
    return this.fb.group({
      nombre: ['', Validators.required],
      telefono: ['', [
        Validators.required,
        Validators.minLength(9),
        Validators.maxLength(9)]
      ],
      email: ['', [Validators.email]],
      acepto: [false, Validators.requiredTrue],
      aceptoPublicidad: [false],
      publicidad: [''],
      id_unidad: ['*'],
      origen: [null],
      tipo: [1],
      tipo_form: ['gris'],
      IP: [null],
      fecha_alta: [new Date()]
    });
  }

  private getIp() {
    this.ipService.getIp().subscribe({
      next: r => {
        this.ip = r.ip;
      }
    })
  }

  private getOrigin(): void {
    const origin = this.route.snapshot.queryParamMap.get('origen');
    this.origin = origin ? origin : ''
  }

  private getOriginFormCita(): void {
    const url = this.route.snapshot.url
    const device = this.getDevice()

    if (String(url) == 'pedir-cita') {

      this.originFormCitation = this.origin == 'sem' ? 'sem '
        + device
        + ' cita' : device
        + ' cita ' + this.origin;
    } else {
      this.originFormCitation = this.origin == 'sem' ? 'sem '
        + this.origenSeccion
        + ' '
        + device : this.origenSeccion
        + ' '
        + device
        + ' '
        + this.origin;
    }
  }

  getDevice(): string {
    if (this.deviceService.isMobile()) {
      return 'movil'
    }
    if (this.deviceService.isTablet()) {
      return 'tablet'
    }
    return 'pc'
  }

  submitForm(): void {
    this.submitted = true;

    if (this.form.valid) {
      this.pedirCita()

    } else {
      Object.values(this.form.controls).forEach(control => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({onlySelf: true});
        }
      });
    }
  }

  private pedirCita(): void {
    this.form.patchValue({
        publicidad: this.publicityValue,
        origen: this.originFormCitation,
        IP: this.ip
      }
    )

    this.solicitud = this.form.value

    this.solicitudService.sendSolicitud(this.solicitud).subscribe({
      next: (res) => {
        this.visible = true;
        this.sendData(res.id_solicitud);
      }
    });
  }

  private sendData(id_solicitud: number) {
    const email = this.form.value.email
    const publicityAccepted = this.form.value.aceptoPublicidad
    const id = 80000000 + id_solicitud
    const data = {
      SourceId: id, // cbaviera_emailing + 10000000 / solicitud_info_promo + 100000 / solicitud NADA,
      Name: this.form.value.nombre,
      PhoneNumber: this.form.value.telefono,
      PhoneDescription: 1,
      PhoneTimeZoneId: '',
      PhoneNumber2: '', PhoneDescription2: 0, PhoneTimeZoneId2: '',
      PhoneNumber3: '', PhoneDescription3: 0, PhoneTimeZoneId3: '',
      PhoneNumber4: '', PhoneDescription4: 0, PhoneTimeZoneId4: '',
      PhoneNumber5: '', PhoneDescription5: 0, PhoneTimeZoneId5: '',
      PhoneNumber6: '', PhoneDescription6: 0, PhoneTimeZoneId6: '',
      PhoneNumber7: '', PhoneDescription7: 0, PhoneTimeZoneId7: '',
      PhoneNumber8: '', PhoneDescription8: 0, PhoneTimeZoneId8: '',
      PhoneNumber9: '', PhoneDescription9: 0, PhoneTimeZoneId9: '',
      PhoneNumber10: '', PhoneDescription10: 0, PhoneTimeZoneId10: '',
      Priority: 100,
      Comments: this.originFormCitation,
      Scheduled: false,
      ScheduleDate: '',
      ScheduleTime: '',
      CapturingAgent: 0,
      CustomData1: email ? email : '',
      CustomData2: publicityAccepted == 'S' ? 'si' : 'no',
      CustomData3: id,
      CallerId: '',
      CallerName: '',
      CustomerId: ''
    }

    //WEBSERVICE DE PRENSECE
    this.presenceService.getToken().subscribe({
      next: (res) => {
        const token = res.token.data
        this.presenceService.sendForm(data, token).subscribe({
          next: (r: any) => {
            this.solicitudService.updateSolicitud(id_solicitud, {respuesta: JSON.stringify(r.msg)}).subscribe();
          }
        })
      }
    })

    //WEBSERVICE DE SALESFORCE
    this.form.value.publicidad = this.form.value.publicidad ? 'S' : 'N';
    this.form.value.IP = this.ip;    
    this.form.value.origen = this.originFormCitation;
    this.salesforceService.sendForm(this.form.value).subscribe({
      next: (r:any) => {
        this.solicitudService.updateSolicitud(id_solicitud, {respuesta_sf: JSON.stringify(r.msg)}).subscribe();
      }
    })
  }

  private get publicityValue(): string {
    return this.form.controls['aceptoPublicidad'].value ? 'S' : 'N';
  }

  public isNotValid(control: string): boolean {
    return !this.form.controls[control].valid && this.submitted;
  }

  public onlyNumbers(event: any){
    const charCode = (event.which)?event.which: event.code;
    return !(charCode > 31 && (charCode < 48 || charCode > 57));
  }

  closeFilter(event: Event, Filtro: HTMLElement): void {
    event.preventDefault();
    $('.modal__filter-form').fadeToggle();
    $('body').toggleClass('modal__open');
    $('.item__slider-home-form').removeClass('opened');
    $('.btn-header-cita').removeClass('opened');
    $('.open-form').removeClass('active');
  }

}
