<footer class="item__footer">
  <div class="wrapper">
    <ul class="item__footer-columns">
      <li>
        <h3 class="item__footer-heading">
          <a class="btn-dropdown" #desplegableClick (click)="Togglefooter($event,desplegableClick)">Conócenos de cerca <img
                  src="{{cdn}}imagenes/icons/icon-arrow-down-blue.svg" alt=""></a>
        </h3>
        <div  class="item__footer-colapsed item-collapsed">
          <ul class="item__footer-link-list">
            <li><a routerLink="clinica-baviera">¿Quiénes somos?</a></li>
            <li><a routerLink="clinica-baviera-idi">I+D+I</a></li>
            <li><a routerLink="prensa-contacto">Área de prensa</a></li>
            <li><a routerLink="international">Pacientes Internacionales</a></li>
            <li><a routerLink="trabajo-empleo">Trabaja con nosotros</a></li>
            <li><a routerLink="clinica-baviera-reclamaciones-quejas">Atención al Paciente</a></li>
            <li><a routerLink="donde-estamos">¿Dónde estamos?</a></li>
            <li><a routerLink="nuestros-medicos">Nuestros médicos</a></li>
          </ul>
        </div>

      </li>
      <li>
        <h3 class="item__footer-heading">
          <a  #desplegableClick2 (click)="Togglefooter($event,desplegableClick2)" class="btn-dropdown">Líderes en oftalmología avanzada <img
                  src="{{cdn}}imagenes/icons/icon-arrow-down-blue.svg" alt=""></a>
        </h3>
        <div  class="item__footer-colapsed item-collapsed">
          <ul class="item__footer-link-list">
            <li><span>Unidad de cirugía refractiva:</span>
              <ul class="">
                <li><a routerLink="refractiva-miopia">Miopía</a>
                </li>
                <li><a routerLink="refractiva-astigmatismo">Astigmatísmo</a>
                </li>
                <li><a routerLink="refractiva-hipermetropia">Hipermetropía</a>
                </li>
              </ul>
            </li>
            <li><a routerLink="operacion-presbicia">Unidad de presbicia</a></li>
            <li><a routerLink="operacion-cataratas">Unidad de cataratas</a></li>
            <li><a routerLink="unidad-de-retina">Unidad de retina</a></li>
            <li><span>Técnicas:</span>
              <ul class="">
                <li><a routerLink="lasik">Lasik</a></li>
                <li><a routerLink="lasek">Lasek</a></li>
                <li><a routerLink="cirugia-refractiva-altas-graduaciones">Lentes intraoculares</a></li>
              </ul>
            </li>
          </ul>
        </div>

      </li>
      <li>
        <h3 class="item__footer-heading">
          <a  #desplegableClick3 (click)="Togglefooter($event,desplegableClick3)" class="btn-dropdown">Otros tratamientos <img
                  src="{{cdn}}imagenes/icons/icon-arrow-down-blue.svg" alt=""></a>
        </h3>
        <div class="item__footer-colapsed item-collapsed">
          <ul class="item__footer-link-list">
            <li><a routerLink="otros_tratamientos_oculoplastia">Oculoplastia</a></li>
            <li><a routerLink="ojo-seco">Ojo seco</a></li>
            <li><a routerLink="cornea-queratocono">Córnea y queratocono</a></li>
            <li><a routerLink="estrabismo">Estrabismo</a></li>
            <li><a routerLink="oftalmologia-pediatrica">Oftalmología Pediátrica</a></li>
            <li><a routerLink="glaucoma">Glaucoma</a></li>
            <li><a routerLink="alteraciones-neuroftalmologicas">Neuroftalmología</a></li>
            
          </ul>
        </div>

      </li>
      <li>
        <h3 class="item__footer-heading">
          <a  #desplegableClick4 (click)="Togglefooter($event,desplegableClick4)"  class="btn-dropdown">Ventajas exclusivas <img
                  src="{{cdn}}imagenes/icons/icon-arrow-down-blue.svg" alt=""></a>
        </h3>
        <div class="item__footer-colapsed item-collapsed">
          <ul class="item__footer-link-list">
            <li><a routerLink="seguros">Acuerdos con aseguradoras</a></li>
            <li><a routerLink="por-que-baviera">¿Por qué Baviera?</a></li>
            <li><a routerLink="cinco-razones-elegirnos">5 Razones para elegirnos</a></li>
            <li><a href="https://areapaciente.clinicabaviera.com/intro/login" target="_blank">Área paciente</a></li>
          </ul>
        </div>

      </li>
      <li>
        <ul class="item__footer-nav-social">
          <li><a class="item__footer-icon-social" target="_blank" href="https://www.instagram.com/clinica_baviera/"><img
            src="{{cdn}}imagenes/icons/rrss/icon-ig.svg" alt="" style="width:12px"></a></li>
            <li><a class="item__footer-icon-social" target="_blank" href="#"><img
              src="{{cdn}}imagenes/icons/rrss/icon-tk.svg" alt="" style="width:12px"></a></li>

          <li><a class="item__footer-icon-social" target="_blank" href="https://www.facebook.com/CLINICABAVIERA"><img
                  src="{{cdn}}imagenes/icons/rrss/icon-fb.svg" alt="" style="width: 7px;"></a></li>
          <li><a class="item__footer-icon-social" target="_blank"
                 href="https://www.linkedin.com/company/clinica-baviera"><img
                  src="{{cdn}}imagenes/icons/rrss/icon-linkedin.svg" alt="" style="width:12px"></a></li>
          <li><a class="item__footer-icon-social" target="_blank" href="https://twitter.com/clinicabaviera"><img
                  src="{{cdn}}imagenes/icons/rrss/icon-x.svg" alt="" style="width: 15px;"></a></li>
          <li><a class="item__footer-icon-social" target="_blank"
                 href="https://www.youtube.com/user/clinicabaviera"><img src="{{cdn}}imagenes/icons/rrss/icon-yt.svg"
                                                                         alt="" style="width:14px"></a></li>
          
          <li><a class="item__footer-icon-social" target="_blank" href="blog/"><img
                  src="{{cdn}}imagenes/icons/rrss/icon-rss.svg" alt="" style="width: 16px;"></a></li>
        </ul>
        <div class="item__footer-awards">
          <a href="https://www.opiniones-verificadas.com/opiniones-clientes/clinicabaviera.com" target="_blank"> <img
                  src="{{cdn}}imagenes/logo-opiniones.png" alt=""> <strong>Ver opiniones verificadas</strong></a>
          <!-- <p><img src="{{cdn}}imagenes/logo-premio-atencion.png" alt=""> Líderes en servicio de <br>Atención al Cliente
            2023</p> -->
          <a href="tel:900180100" class="item__footer-awards-tel visible-lg "><img
                  src="{{cdn}}imagenes/icons/icon-phone-blue.svg" alt="">900 180 100</a>

        </div>

      </li>
      <li>
        <a href="tel:900180100" class="hidden-lg item__footer-awards-tel"><img
                src="{{cdn}}imagenes/icons/icon-phone-blue.svg" alt="">900 180 100</a>
        <div class="item__footer-awards-sociedades">
          <a href="https://www.oftalmoseo.com/" target="_blank">
          <img src="{{cdn}}imagenes/logo-seo.png" alt=""></a>
          <a href="https://secoir.org/" target="_blank">
          <img src="{{cdn}}imagenes/logo-secoir.png" alt=""></a>
          <a href="https://www.escrs.org/" target="_blank">
          <img src="{{cdn}}imagenes/logo-escrs.png" alt=""></a>

        </div>

      </li>
      <li>
        <h3 class="item__footer-heading hidden-lg">
          <a href="#item-footer-5" class="btn-dropdown">Legales<img src="{{cdn}}imagenes/icons/icon-arrow-down-blue.svg"
                                                                    alt=""></a>
        </h3>
        <div id="item-footer-5" class="item__footer-colapsed item-collapsed">
          <ul class="item__footer-link-list item-legales">

            <li><a href=" condiciones">Política de Privacidad</a></li>
            <li><a href="aviso-legal">Aviso legal</a></li>
            <li><a href="cookies">Política de cookies</a></li>
            <li><a href="javascript:klaro.show();">Personalizar cookies</a></li>
            <li><a href="https://www.grupobaviera.es/accionistas-inversores/">Accionistas e inversores</a></li>
            <li><a href="plan-de-igualdad">Plan de igualdad</a></li>
            <li><a href="https://www.clinicabaviera.com/blog/">Blog</a> <a href="sitemaps">Sitemap</a></li>
          </ul>

        </div>

      </li>
      <li class="item__footer-doctores">
        <p>Los textos de contenido médico incluidos en la web han sido escritos y revisados por <a
          href="nuestros-medicos" target="_blank" class="u-underline">facultativos médicos especializados.</a></p>
      </li>
    </ul>


  </div>

</footer>


<div class="visible-xs item__fixed-cta">
  <a href="https://www.clinicabaviera.com/pide-cita/?idCampanya=web" class="btn-orange " target="_blank" > <img src="{{cdn}}imagenes/icons/icon-calendar-white.svg" alt="" style="width: 28px;">Cita Online</a>
  <a href="#" class="btn-tlf open-form" #formOpen (click)="toggleForm($event)"> <img src="{{cdn}}imagenes/icons/icon-phone-white.svg" alt=""></a>
  <a href="https://api.whatsapp.com/send?phone=34900180100" class="btn-wht" target="_blank"> <img src="{{cdn}}imagenes/icons/icon-whatsapp.svg" alt=""></a>

</div>
<div class="modal__filter-form" style="display: none" #filterClose (click)="closeFilter($event, filterClose)"></div>

<div class="btn-whatsapp-desktop">
  <a class="btn-footer-whatsapp" href="https://api.whatsapp.com/send?phone=34900180100" target="_blank"><img src="{{cdn}}imagenes/icons/whatsapp2.png" width="45" height="44" alt="icono Whatsapps"></a>
</div>
