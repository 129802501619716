import { Component } from '@angular/core';
import {environment} from "../../../../environments/environment";
@Component({
  selector: 'app-banner-cataratas-primera-consulta-mobile',
  templateUrl: './banner-cataratas-primera-consulta-mobile.component.html',
  styleUrls: ['./banner-cataratas-primera-consulta-mobile.component.css']
})
export class BannerCataratasPrimeraConsultaMobileComponent {
  public cdn: string;
  constructor() {
    this.cdn = environment.cdn;
   }
  toggleForm(event: Event): void {
    event.preventDefault();
    const itemSliderHomeForm = document.querySelector('.item__slider-home-form') as HTMLElement;
    const targetPosition = itemSliderHomeForm.getBoundingClientRect().top + window.scrollY - 100;
    window.scrollTo({
      top: targetPosition,
      behavior: 'smooth',
    });

    itemSliderHomeForm.classList.toggle('opened');
    const modalFilterForm = document.querySelector('.modal__filter-form') as HTMLElement;
    modalFilterForm.style.display = modalFilterForm.style.display == 'none' ? 'block' : 'none';
  }
  showCondiciones(event: Event): void {
    event.preventDefault();
    const itemLegales = document.querySelector('.item__legales') as HTMLElement;
    const btnCodicionesMobile = document.querySelector('.condiciones-mobile') as HTMLElement;
    itemLegales.classList.toggle('active');
    btnCodicionesMobile.classList.toggle('active');   
  }  
  
}
