import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { SlickCarouselComponent } from "ngx-slick-carousel";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import * as $ from "jquery";
import { SLIDE_CONFIG } from "../../utils/const/slideConfig.const";
import { IProvince } from "../../interfaces/province.interface";
import { ProvinceService } from "../../services/province.service";
import { ProvinceRequest } from "../../models/provinceRequestModel";
import { LocationType } from "../../utils/types/location.type";
import { Router } from "@angular/router";
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'app-map-finder-retina',
  templateUrl: './map-finder-retina.component.html',
  styleUrls: ['./map-finder-retina.component.css']
})
export class MapFinderRetinaComponent implements OnInit {
  @ViewChild('slickModal')
  public cdn: string;
  public slickModal!: SlickCarouselComponent;
  public form!: FormGroup;
  private submitted = false;
  public provinces: IProvince[] = []
  public notFound = false;

  public provincias = [
    { codigo_postal: "01", provincia: "Álava"},
    { codigo_postal: "02", provincia: "Albacete"},
    { codigo_postal: "03", provincia: "Alicante"},
    { codigo_postal: "04", provincia: "Almería"},
    { codigo_postal: "33", provincia: "Asturias"},
    { codigo_postal: "05", provincia: "Ávila"},
    { codigo_postal: "06", provincia: "Badajoz"},
    { codigo_postal: "07", provincia: "Baleares"},
    { codigo_postal: "08", provincia: "Barcelona"},
    { codigo_postal: "09", provincia: "Burgos"},
    { codigo_postal: "10", provincia: "Cáceres"},
    { codigo_postal: "11", provincia: "Cádiz"},
    { codigo_postal: "39", provincia: "Cantabria"},
    { codigo_postal: "12", provincia: "Castellón"},
    { codigo_postal: "51", provincia: "Ceuta"},
    { codigo_postal: "13", provincia: "Ciudad Real"},
    { codigo_postal: "14", provincia: "Córdoba"},
    { codigo_postal: "16", provincia: "Cuenca"},
    { codigo_postal: "17", provincia: "Gerona"},
    { codigo_postal: "18", provincia: "Granada"},
    { codigo_postal: "19", provincia: "Guadalajara"},
    { codigo_postal: "20", provincia: "Gipuzkoa"},
    { codigo_postal: "21", provincia: "Huelva"},
    { codigo_postal: "22", provincia: "Huesca"},
    { codigo_postal: "23", provincia: "Jaén"},
    { codigo_postal: "15", provincia: "La Coruña"},
    { codigo_postal: "26", provincia: "La Rioja"},
    { codigo_postal: "35", provincia: "Las Palmas"},
    { codigo_postal: "24", provincia: "León"},
    { codigo_postal: "25", provincia: "Lérida"},
    { codigo_postal: "27", provincia: "Lugo"},
    { codigo_postal: "28", provincia: "Madrid"},
    { codigo_postal: "29", provincia: "Málaga"},
    { codigo_postal: "52", provincia: "Melilla"},
    { codigo_postal: "30", provincia: "Murcia"},
    { codigo_postal: "31", provincia: "Navarra"},
    { codigo_postal: "32", provincia: "Ourense"},
    { codigo_postal: "34", provincia: "Palencia"},
    { codigo_postal: "36", provincia: "Pontevedra"},
    { codigo_postal: "37", provincia: "Salamanca"},
    { codigo_postal: "38", provincia: "Santa Cruz de Tenerife"},
    { codigo_postal: "40", provincia: "Segovia"},
    { codigo_postal: "41", provincia: "Sevilla"},
    { codigo_postal: "42", provincia: "Soria"},
    { codigo_postal: "43", provincia: "Tarragona"},
    { codigo_postal: "44", provincia: "Teruel"},
    { codigo_postal: "45", provincia: "Toledo"},
    { codigo_postal: "46", provincia: "Valencia"},
    { codigo_postal: "47", provincia: "Valladolid"},
    { codigo_postal: "48", provincia: "Vizcaya"},
    { codigo_postal: "49", provincia: "Zamora"},
    { codigo_postal: "50", provincia: "Zaragoza"}
  ];

  public readonly slideConfig = SLIDE_CONFIG.mapFinderComponent

  constructor(
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private provinceService: ProvinceService) {
      this.cdn = environment.cdn;
  }

  ngOnInit() {
    this.initForm()
    this.getProvinces()
  }

  private getProvinces(): void {
    const request = this.buildProvinceRequest()
    this.provinceService.getProvinces(request).subscribe({
      next: r => {
        this.provinces = r
      }
    })
  }

  private buildProvinceRequest(): ProvinceRequest {
    const request = new ProvinceRequest()
    request.setClinics(true)
    request.setOnlySliders(true)
    request.setOrderBy('nombre')
    request.setSort('ASC')
    request.setClinicaIER(true)
    return request
  }

  private getProvinceByAreaCode(areaCode: string, countryCode: string): void {
    if (countryCode != 'ES') {
      this.provinces = [];
      this.notFound = true;
      return
    }

    const request = new ProvinceRequest()
    request.setClinics(true)
    request.setOnlySliders(true)
    request.setClinicaIER(true)

    this.provinceService.getProvinceByAreaCode(request, areaCode).subscribe({
      next: r => {
        if (r == null){
          this.provinces = [];
          this.notFound = true;
        } else {
          this.provinces = [r]
          this.notFound = false;
        }
      }
    })
  }

  public onSubmit(): void {
    this.submitted = true

    for (const i in this.form.controls) {
      this.form.controls[i].markAsDirty();
      this.form.controls[i].updateValueAndValidity();
    }

    if (this.form.valid) {
      this.findProvince('search')
    }
  }

  private initForm(): void {
    this.form = this.fb.group({
      address: ['', Validators.required]
    });
  }

  public get isInvalid() {
    return this.form.invalid && this.submitted;
  }

  public findProvince(type: LocationType) {
    if (type === "search") {
      this.getLocationSearch()
    }

    if (type === "reverse") {
      this.getLocationReverse()
    }
  }

  async getLocationReverse() {
    const geocoder = new google.maps.Geocoder();
    let postalCode:any = '28'
    let country = 'ES'
    let location: google.maps.LatLngLiteral = {
      lat: 40.416775,
      lng: -3.703790
    }

    await navigator.geolocation.getCurrentPosition(position => {
      location.lat = position.coords.latitude
      location.lng = position.coords.longitude
    })

    await geocoder.geocode({ location }).then(({ results }) => {
      results[0].address_components.forEach(item => {
        if (item.types[0] === "postal_code") {
          postalCode = item.long_name.slice(0, 2)
        } else if (item.types[0] === "administrative_area_level_2" && this.provincias.find(({ provincia }) => provincia === item.long_name)) {
          postalCode = this.provincias.find(({ provincia }) => provincia === item.long_name)?.codigo_postal
        }

        if (item.types[0] === "country") {
          country = item.short_name
        }
      })
    }).catch(err => {
      //para que muestre sin resultados cuando hay un error
      postalCode = '00'
      country = 'XX'
    })

    this.getProvinceByAreaCode(postalCode, country)
  }

  private async getLocationSearch() {
    const address = this.form.value.address
    let postalCode:any = '28'
    let country = 'ES'
    const geocoder = new google.maps.Geocoder();

    await geocoder.geocode({ address: address }).then(({ results }) => {
      results[0].address_components.forEach(item => {
        if (item.types[0] === "postal_code") {
          postalCode = item.long_name.slice(0, 2)
        } else if (item.types[0] === "administrative_area_level_2" && this.provincias.find(({ provincia }) => provincia === item.long_name)) {
          postalCode = this.provincias.find(({ provincia }) => provincia === item.long_name)?.codigo_postal
        }

        if (item.types[0] === "country") {
          country = item.short_name
        }
      })
    }).catch(err => {
      //para que muestre sin resultados cuando hay un error
      postalCode = '00'
      country = 'XX'
    })

    this.getProvinceByAreaCode(postalCode, country)
  }

  toggleResultado(event:Event, desplegableResultado:HTMLAnchorElement):void{
    event.preventDefault();
    $(desplegableResultado)
    .siblings('.item__card-clinica-listado')
    .slideToggle();
    $(desplegableResultado).toggleClass('active');
  }
}
