<section class="opiniones bg-opiniones ">
    <div class="wrapper">
        <h2 class="opiniones__title">Conoce las opiniones reales <strong>de nuestros pacientes</strong></h2>


        <div class="opiniones__box-puntuacion">
            <div class="opiniones__box-logo">
                <img src="{{cdn}}imagenes/icons/icon-award.svg" alt="">
                <img src="{{cdn}}imagenes/logo-google.svg" alt="">
            </div>
            <div class="opiniones__box-resultado">
                <div class="opiniones__stars">
                    <img src="{{cdn}}imagenes/icons/icon-yellow-star.svg" alt="">
                    <img src="{{cdn}}imagenes/icons/icon-yellow-star.svg" alt="">
                    <img src="{{cdn}}imagenes/icons/icon-yellow-star.svg" alt="">
                    <img src="{{cdn}}imagenes/icons/icon-yellow-star.svg" alt="">
                    <img src="{{cdn}}imagenes/icons/icon-yellow-star.svg" alt="">
                </div>
                <div class="opiniones__puntuacion">
                    <p><strong>4,8</strong>/5</p>

                </div>
            </div> <!-- end opiniones__box-resultado -->


         </div><!-- end opiniones__box-puntuacion -->

         <div class="opiniones__items">
            <div class="opiniones__items-slider">
                <ngx-slick-carousel [config]="slideConfigB">

                    <div ngxSlickItem>
                        <div class="opiniones__items-card">
                            <div class="opiniones__stars">
                                <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                                <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                                <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                                <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                                <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            </div>
                            <div class="opiniones__items-text">
                                <p class="opiniones__items-text-paciente">
                                    PACIENTE
                                </p>
                                <p class="opiniones__items-text-nombre">
                                    Paqui Marti
                                </p>
                                <p class="opiniones__items-text-date">Agosto de 2023</p>
                                <p class="opiniones__items-text-quote">
                                    Recomiendo la operación de hipermetropía+presbicia+cataratas, ha sido un cambio muy positivo en mi vida, no tener qué llevar gafas!!!, Para mí era muy molesto, hace 3 meses qué las perdí de vista y estoy contentísima, todo el personal de la clínica Baviera de Sabadell són muy buenos profesionales y antetodo personas, igual que en la clínica de Barcelona Diagonal,
                                    Gracias a todos! En especial al Dr. Farías..</p>
                            </div>
                         </div><!--  end opiniones__items-card -->
                </div>

                    <div ngxSlickItem>
                        <div class="opiniones__items-card">
                            <div class="opiniones__stars">
                                <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                                <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                                <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                                <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                                <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            </div>
                            <div class="opiniones__items-text">
                                <p class="opiniones__items-text-paciente">
                                    PACIENTE
                                </p>
                                <p class="opiniones__items-text-nombre">
                                    Esther Gandia Barbera
                                </p>
                                <p class="opiniones__items-text-date">Agosto de 2023</p>
                                <p class="opiniones__items-text-quote">
                                    Grandes profesionales. Operado de cataratas y todo salío genial. Muchas gracias por todo!!</p>
                            </div>
                         </div><!--  end opiniones__items-card -->
                </div>

                <div ngxSlickItem>
                    <div class="opiniones__items-card">
                        <div class="opiniones__stars">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                        </div>
                        <div class="opiniones__items-text">
                            <p class="opiniones__items-text-paciente">
                                PACIENTE
                            </p>
                            <p class="opiniones__items-text-nombre">
                                Agurtzane Elkoro Basurko
                            </p>
                            <p class="opiniones__items-text-date">Agosto de 2023</p>
                            <p class="opiniones__items-text-quote">Estoy operada de catarata con lentes mutifocales y estoy muy contenta por que me he librado de las gafas,que eran un incordio.</p>
                        </div>
                     </div><!--  end opiniones__items-card -->
            </div>

            <div ngxSlickItem>
                <div class="opiniones__items-card">
                    <div class="opiniones__stars">
                        <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                        <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                        <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                        <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                        <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                    </div>
                    <div class="opiniones__items-text">
                        <p class="opiniones__items-text-paciente">
                            PACIENTE
                        </p>
                        <p class="opiniones__items-text-nombre">
                            Gloria Rodriguez
                        </p>
                        <p class="opiniones__items-text-date">Agosto de 2023</p>
                        <p class="opiniones__items-text-quote">Operada con el Dr. Salaberria de cataratas y estoy muy contenta con el resultado, y atenciones tanto de su parte, como de todo el personal</p>
                    </div>
                 </div><!--  end opiniones__items-card -->
        </div>

        <div ngxSlickItem>
            <div class="opiniones__items-card">
                <div class="opiniones__stars">
                    <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                    <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                    <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                    <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                    <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                </div>
                <div class="opiniones__items-text">
                    <p class="opiniones__items-text-paciente">
                        PACIENTE
                    </p>
                    <p class="opiniones__items-text-nombre">
                        Angela Castro
                    </p>
                    <p class="opiniones__items-text-date">Agosto de 2023</p>
                    <p class="opiniones__items-text-quote">Me operaron de los dos ojos miopía y catarata son muy profesionales y muy atentos</p>
                </div>
             </div><!--  end opiniones__items-card -->
    </div>

    <div ngxSlickItem>
        <div class="opiniones__items-card">
            <div class="opiniones__stars">
                <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
            </div>
            <div class="opiniones__items-text">
                <p class="opiniones__items-text-paciente">
                    PACIENTE
                </p>
                <p class="opiniones__items-text-nombre">
                    José Conde Nieva
                </p>
                <p class="opiniones__items-text-date">Junio de 2023</p>
                <p class="opiniones__items-text-quote">Operación de cataratas, todo fenomenal</p>
            </div>
         </div><!--  end opiniones__items-card -->
</div>

<div ngxSlickItem>
    <div class="opiniones__items-card">
        <div class="opiniones__stars">
            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
        </div>
        <div class="opiniones__items-text">
            <p class="opiniones__items-text-paciente">
                PACIENTE
            </p>
            <p class="opiniones__items-text-nombre">
                Imanol Larrañaga
            </p>
            <p class="opiniones__items-text-date">Mayo de 2023</p>
            <p class="opiniones__items-text-quote">Operado de cataratas, excepcional trato y he ganado gracias a la cirugia calidad de cida.</p>
        </div>
     </div><!--  end opiniones__items-card -->
</div>

<div ngxSlickItem>
    <div class="opiniones__items-card">
        <div class="opiniones__stars">
            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
        </div>
        <div class="opiniones__items-text">
            <p class="opiniones__items-text-paciente">
                PACIENTE
            </p>
            <p class="opiniones__items-text-nombre">
                Lilian Iturra Aguilera
            </p>
            <p class="opiniones__items-text-date">Mayo de 2023</p>
            <p class="opiniones__items-text-quote">Un trato especial y excepcional.  Mi abuela se operó de cataratas y ha recuperado el 100 por cien de vista‼️. Simplemente recomendamos la clínica ‼️</p>
        </div>
     </div><!--  end opiniones__items-card -->
</div>

<div ngxSlickItem>
    <div class="opiniones__items-card">
        <div class="opiniones__stars">
            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
        </div>
        <div class="opiniones__items-text">
            <p class="opiniones__items-text-paciente">
                PACIENTE
            </p>
            <p class="opiniones__items-text-nombre">
                Mónica Miranda
            </p>
            <p class="opiniones__items-text-date">Febrero de 2023</p>
            <p class="opiniones__items-text-quote">Hace años me operé de miopía aquí y ahora lo he hecho de cataratas, buenos profesionales, sobretodo la Dra. Ruiz, que ha sido mi cirujana las 2 veces</p>
        </div>
     </div><!--  end opiniones__items-card -->
</div>

<div ngxSlickItem>
    <div class="opiniones__items-card">
        <div class="opiniones__stars">
            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
        </div>
        <div class="opiniones__items-text">
            <p class="opiniones__items-text-paciente">
                PACIENTE
            </p>
            <p class="opiniones__items-text-nombre">
                José Vicente Rodríguez Barranco
            </p>
            <p class="opiniones__items-text-date">Febrero de 2023</p>
            <p class="opiniones__items-text-quote">Gran trato de todo el equipo y resultado esperado sin complicaciones.
                En mi caso cataratas con implante de lentes toricas.
                Gracias</p>
        </div>
     </div><!--  end opiniones__items-card -->
</div>

<div ngxSlickItem>
    <div class="opiniones__items-card">
        <div class="opiniones__stars">
            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
        </div>
        <div class="opiniones__items-text">
            <p class="opiniones__items-text-paciente">
                PACIENTE
            </p>
            <p class="opiniones__items-text-nombre">
                Miguel José Villatoro Periañez
            </p>
            <p class="opiniones__items-text-date">Febrero de 2023</p>
            <p class="opiniones__items-text-quote">Gran trato personal excelente la operación de cataratas grandes profesionales todos y encantado de haberme operado en Clínica Baviera</p>
        </div>
     </div><!--  end opiniones__items-card -->
</div>

<div ngxSlickItem>
    <div class="opiniones__items-card">
        <div class="opiniones__stars">
            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
        </div>
        <div class="opiniones__items-text">
            <p class="opiniones__items-text-paciente">
                PACIENTE
            </p>
            <p class="opiniones__items-text-nombre">
                Mari Carmen González
            </p>
            <p class="opiniones__items-text-date">Febrero de 2023</p>
            <p class="opiniones__items-text-quote">Muy recomendable me operaron de cataratas y presbicia  estoy encantada con los resultados personal tanto médicos enfermeras  muy cercanos</p>
        </div>
     </div><!--  end opiniones__items-card -->
</div>



                <div ngxSlickItem>
                    <div class="opiniones__items-card">
                        <div class="opiniones__stars">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                        </div>
                        <div class="opiniones__items-text">
                            <p class="opiniones__items-text-paciente">
                                PACIENTE
                            </p>
                            <p class="opiniones__items-text-nombre">
                                Esther Gandia Barbera
                            </p>
                            <p class="opiniones__items-text-quote">
                                Grandes profesionales. Operado de cataratas y todo salío genial. Muchas gracias por todo!!</p>
                        </div>
                     </div><!--  end opiniones__items-card -->
            </div>

                    <div ngxSlickItem>
                        <div class="opiniones__items-card">
                            <div class="opiniones__stars">
                                <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                                <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                                <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                                <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                                <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            </div>
                            <div class="opiniones__items-text">
                                <p class="opiniones__items-text-paciente">
                                    PACIENTE

                                </p>
                                <p class="opiniones__items-text-nombre">
                                    Francisco Peco
                                </p>
                                <p class="opiniones__items-text-quote">
                                    Tenia cataratas, me operaron de un ojo y a la semana siguiente del otro. Las operaciones fueron rápidas e indoloras, durante las intervenciones el doctor me iba explicando todo lo que hacía, estoy super contento sobre todo a la hora de conducir. El personal de la clinica muy atentos y agradables.</p>
                            </div>
                         </div><!--  end opiniones__items-card -->
                </div>
                <div ngxSlickItem>
                    <div class="opiniones__items-card">
                        <div class="opiniones__stars">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                        </div>
                        <div class="opiniones__items-text">
                            <p class="opiniones__items-text-paciente">
                                PACIENTE

                            </p>
                            <p class="opiniones__items-text-nombre">
                                Lucía Sotorrio Espinel
                            </p>
                            <p class="opiniones__items-text-quote">
                                ¡Excelente Clinica! Mi padre se operó de cataratas, y ha sido todo inmejorable: atención, intervención, hospitalidad, precio, puntualidad, etc. La recomiendo al 100%
                            </p>


                        </div>


                     </div><!--  end opiniones__items-card -->
                </div>
                <div ngxSlickItem>
                    <div class="opiniones__items-card">
                        <div class="opiniones__stars">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                        </div>
                        <div class="opiniones__items-text">
                            <p class="opiniones__items-text-paciente">
                                PACIENTE

                            </p>
                            <p class="opiniones__items-text-nombre">
                                José Barón Nuñez
                            </p>
                            <p class="opiniones__items-text-quote">
                                Me han operado de cataratas, el primer ojo. Hasta ahora todo excelente.
                            </p>


                        </div>


                     </div><!--  end opiniones__items-card -->
                </div>
                <div ngxSlickItem>
                    <div class="opiniones__items-card">
                        <div class="opiniones__stars">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                        </div>
                        <div class="opiniones__items-text">
                            <p class="opiniones__items-text-paciente">
                                PACIENTE

                            </p>
                            <p class="opiniones__items-text-nombre">
                                Pepi Molina Uroz    
                            </p>
                            <p class="opiniones__items-text-quote">
                                Me operé de cataratas y miopía con la Dra. Camargo. Muy contenta con el resultado final. Personal muy agradable. Volvería a repetir la experiencia!
                            </p>


                        </div>


                     </div><!--  end opiniones__items-card -->
                </div>

                <div ngxSlickItem>
                    <div class="opiniones__items-card">
                        <div class="opiniones__stars">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                        </div>
                        <div class="opiniones__items-text">
                            <p class="opiniones__items-text-paciente">
                                PACIENTE

                            </p>
                            <p class="opiniones__items-text-nombre">
                                Jesús García Arribas  
                            </p>
                            <p class="opiniones__items-text-quote">
                                Operación de cataratas con resultado perfecto. Trato impecable. Felicidades a todo el equipo.
                            </p>


                        </div>


                     </div><!--  end opiniones__items-card -->
                </div>
                <div ngxSlickItem>
                    <div class="opiniones__items-card">
                        <div class="opiniones__stars">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                        </div>
                        <div class="opiniones__items-text">
                            <p class="opiniones__items-text-paciente">
                                PACIENTE

                            </p>
                            <p class="opiniones__items-text-nombre">
                                Radalex1 Lanciego
                            </p>
                            <p class="opiniones__items-text-quote">
                                Mi padre acaba de operarse de los 2 ojos de cataratas y fenomenal. Con una semana de diferencia los dos. Las instalaciones son increíbles, muy organizado el personal y muy amables y atentos, muy puntuales en las citas y muy bien de precio. Sin lugar a dudas un lugar muy profesional y recomendable.
                            </p>


                        </div>


                     </div><!--  end opiniones__items-card -->
                </div>

                <div ngxSlickItem>
                    <div class="opiniones__items-card">
                        <div class="opiniones__stars">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                        </div>
                        <div class="opiniones__items-text">
                            <p class="opiniones__items-text-paciente">
                                PACIENTE

                            </p>
                            <p class="opiniones__items-text-nombre">
                                Patricia García
                            </p>
                            <p class="opiniones__items-text-quote">
                                Muchas gracias Doctor de Casas, era una catarata difícil y no pensé que en un periodo de 24 horas pudiera ver sin gafas. Aún me queda la recuperación pero estoy muy contenta con el resultado y la atención recibida tanto por el Doctor como por todo el equipo que forma parte de la clínica.
                            </p>


                        </div>


                     </div><!--  end opiniones__items-card -->
                </div>
                <div ngxSlickItem>
                    <div class="opiniones__items-card">
                        <div class="opiniones__stars">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                        </div>
                        <div class="opiniones__items-text">
                            <p class="opiniones__items-text-paciente">
                                PACIENTE

                            </p>
                            <p class="opiniones__items-text-nombre">
                                Mila Pinedo
                            </p>
                            <p class="opiniones__items-text-quote">
                                Hola !yo soy clienta más de 20 años..lleve a mi suegra..me opere de cataratas y ahora de unas verrugas..y el trato súper amables, atentos..y te tratan con un cariño y con toda confianza..os lo recomiendo..son geniales
                            </p>


                        </div>


                     </div><!--  end opiniones__items-card -->
                </div>
                <div ngxSlickItem>
                    <div class="opiniones__items-card">
                        <div class="opiniones__stars">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                        </div>
                        <div class="opiniones__items-text">
                            <p class="opiniones__items-text-paciente">
                                PACIENTE

                            </p>
                            <p class="opiniones__items-text-nombre">
                                Ángel Sánchez
                            </p>
                            <p class="opiniones__items-text-quote">
                                Tras hacerme una intervención el doctor Reza de cataratas con implante de lente intraocular, digo que mi experiencia ha sido muy positiva. Recomiendo a quien esté pensando en hacerse este tipo de cirugía se la realice en esta clínica.
                            </p>


                        </div>


                     </div><!--  end opiniones__items-card -->
                </div>
                <div ngxSlickItem>
                    <div class="opiniones__items-card">
                        <div class="opiniones__stars">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                        </div>
                        <div class="opiniones__items-text">
                            <p class="opiniones__items-text-paciente">
                                PACIENTE

                            </p>
                            <p class="opiniones__items-text-nombre">
                                Emilio de los Reyes
                            </p>
                            <p class="opiniones__items-text-quote">
                                Realicé operación de cataratas. Trato excelente por parte de todo el equipo y muy profesionales.
                            </p>


                        </div>


                     </div><!--  end opiniones__items-card -->
                </div>
                <div ngxSlickItem>
                    <div class="opiniones__items-card">
                        <div class="opiniones__stars">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                        </div>
                        <div class="opiniones__items-text">
                            <p class="opiniones__items-text-paciente">
                                PACIENTE

                            </p>
                            <p class="opiniones__items-text-nombre">
                                Javier Ramos
                            </p>
                            <p class="opiniones__items-text-quote">
                                Operado de cataratas en los dos ojos y he quedado muy bien y satisfecho. El trato muy cercano y muy amable. Un diez
                            </p>


                        </div>


                     </div><!--  end opiniones__items-card -->
                </div>
                <div ngxSlickItem>
                    <div class="opiniones__items-card">
                        <div class="opiniones__stars">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                        </div>
                        <div class="opiniones__items-text">
                            <p class="opiniones__items-text-paciente">
                                PACIENTE

                            </p>
                            <p class="opiniones__items-text-nombre">
                                Chonita62
                            </p>
                            <p class="opiniones__items-text-quote">
                                He de decir que me he operado de cataratas y me han implantando una lente que no veía ni de cerca ni de lejos, no puedo estar más contenta, con todo el personal, con la doctora Cordero, que es una excelente persona y una gran profesional, el trato por parte de todas las personas que forma el equipo, tanto en la clínica de la calle Alcala, tanto como la clínica donde realizan las operaciones, están super pendientes de las personas, y lo mejor de todo, es que vuelvo a ver sin gafas, lo recomiendo al 100%.
                            </p>


                        </div>


                     </div><!--  end opiniones__items-card -->
                </div>
                <div ngxSlickItem>
                    <div class="opiniones__items-card">
                        <div class="opiniones__stars">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                        </div>
                        <div class="opiniones__items-text">
                            <p class="opiniones__items-text-paciente">
                                PACIENTE

                            </p>
                            <p class="opiniones__items-text-nombre">
                                José Miguel Gómez Albarracín
                            </p>
                            <p class="opiniones__items-text-quote">
                                Recientemente he ido a acompañar a mi madre por una cirugía de cataratas con la Dra. Gladys Mora, tengo que destacar su gran profesionalidad, humanidad y buen trato, ha sido gratificante, mi madre está muy contenta con el resultado. La atención del personal de clínica también ha sido correcta.
                            </p>


                        </div>


                     </div><!--  end opiniones__items-card -->
                </div>
                <div ngxSlickItem>
                    <div class="opiniones__items-card">
                        <div class="opiniones__stars">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                        </div>
                        <div class="opiniones__items-text">
                            <p class="opiniones__items-text-paciente">
                                PACIENTE

                            </p>
                            <p class="opiniones__items-text-nombre">
                                Vicente Fletes Roca
                            </p>
                            <p class="opiniones__items-text-quote">
                                Estoy operado de cataratas en los dos ojos, a día de hoy tengo una visión al 100% tanto los servicios la operacion y la atención han sido extraordinarios.
                            </p>


                        </div>


                     </div><!--  end opiniones__items-card -->
                </div>
                <div ngxSlickItem>
                    <div class="opiniones__items-card">
                        <div class="opiniones__stars">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star" alt="">
                        </div>
                        <div class="opiniones__items-text">
                            <p class="opiniones__items-text-paciente">
                                PACIENTE

                            </p>
                            <p class="opiniones__items-text-nombre">
                                Pascual Miura
                            </p>
                            <p class="opiniones__items-text-quote">
                                He sido operado de cataratas a mi entera satisfacción.
                            </p>


                        </div>


                     </div><!--  end opiniones__items-card -->
                </div>
                <div ngxSlickItem>
                    <div class="opiniones__items-card">
                        <div class="opiniones__stars">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                        </div>
                        <div class="opiniones__items-text">
                            <p class="opiniones__items-text-paciente">
                                PACIENTE

                            </p>
                            <p class="opiniones__items-text-nombre">
                                Fernando Ezquerra
                            </p>
                            <p class="opiniones__items-text-quote">
                                Revisión de cataratas operadas hace un año y consulta de problema visual en el ojo derecho. Como ya ocurrió el año pasado, buena atención de todo el equipo y doblemente la del doctor, que revisó la operación hecha el año pasado y atendió con solvencia la nueva problemática visual.
                            </p>


                        </div>


                     </div><!--  end opiniones__items-card -->
                </div>
                <div ngxSlickItem>
                    <div class="opiniones__items-card">
                        <div class="opiniones__stars">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                        </div>
                        <div class="opiniones__items-text">
                            <p class="opiniones__items-text-paciente">
                                PACIENTE

                            </p>
                            <p class="opiniones__items-text-nombre">
                                Maya Gala
                            </p>
                            <p class="opiniones__items-text-quote">
                                Fuimos por una operación de cataratas algo delicada,  nos lo recomendó un familiar. La atención, comunicación y seguimiento están siendo magníficos. La operación duró apenas treinta minutos y el paciente no sintió dolor ni molestia ninguno. Totalmente recomendables. Grandes profesionales.
                            </p>


                        </div>


                     </div><!--  end opiniones__items-card -->
                </div>
                <div ngxSlickItem>
                    <div class="opiniones__items-card">
                        <div class="opiniones__stars">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                            <img src="{{cdn}}imagenes/icons/icon-yellow-star-2.svg" alt="">
                        </div>
                        <div class="opiniones__items-text">
                            <p class="opiniones__items-text-paciente">
                                PACIENTE

                            </p>
                            <p class="opiniones__items-text-nombre">
                                Neus Escriva 
                            </p>
                            <p class="opiniones__items-text-quote">
                                Moperaron a mi madre de cataratas y no puedo haber elegido mejor clínica q  Clínica Baviera Gandia. Mi prima y tia tb han ido y muy contentas. Muy profesionales!!!!!
                            </p>


                        </div>


                     </div><!--  end opiniones__items-card -->
                </div>


            </ngx-slick-carousel>
            </div><!--  end opiniones__items-slider -->

          </div><!-- end opiniones__items -->

    </div><!--  end wrapper -->

</section>