<section class="bg-grey">
  <div *ngIf="province" class="wrapper">
    <div class="item__column items__padded u-jc-center">
      <div class="item__column-1">
        <h2 class="title__h2">Nuestras clínicas en <strong>{{ province.nombre | titlecase }}</strong></h2>
      </div>
      <div class="item__column-2 item-centered">
        <div class="hidden-xs contenedor-mapa">
          <ul class="clinicas-mapa">
            <li *ngFor="let clinic of clinics" class="clinicas-loc c-{{clinic.id_baviera_clinica}}" id="m-{{clinic.id_baviera_clinica}}">
              <a routerLink="/clinica-oftalmologica/{{ clinic.nombre_seo }}">
                <span>
                  <img src="{{cdn}}imagenes/icons/ico-location-blue.png" alt="" class="initial">
                  <img src="{{cdn}}imagenes/icons/ico-location-selected.png" alt="" class="active">
                  <div class="name">
                    <p *ngIf="esPoblacion(clinic.cp); else capital">{{ clinic.poblacion }}</p>
                    <ng-template #capital><p>{{ clinic.poblacion }}.</p> {{ clinic.direccion }}</ng-template>
                  </div>
                </span>
              </a>
            </li>
          </ul>

          <img src="{{cdn}}imagenes/mapa-{{ province.id_provincia }}.png" alt="{{ province.nombre | titlecase }}" width="495" height="551">
          <!-- end mapa -->
        </div>
      </div>
      <!--  end item__column-2 -->
      <div class="item-column-2 item-centered gs_appear_up" gsAppear>
        <h2 class="title__h4 hidden-xs u-margin-bottom-sm"><strong>Clínicas en {{ province.nombre | titlecase }}</strong></h2>
        <div class="resultados-clinicas">
          <div class="item-clinica" *ngFor="let clinic of clinics">
            <p>{{ clinic.nombre }}</p>
            <a routerLink="/clinica-oftalmologica/{{ clinic.nombre_seo }}" class="btn">Ver Clínica</a>
          </div>
        </div>

      </div>
      <!--  end item__column-2 -->
    </div>
  </div>

</section>
