import {Component} from '@angular/core';
import * as $ from "jquery";
import {environment} from "../../../environments/environment";
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent {
  public cdn: string;
  constructor() {
    this.cdn = environment.cdn;
   }
  toggleForm(event: Event): void {
    event.preventDefault();
    const itemSliderHomeForm = document.querySelector('.item__slider-home-form') as HTMLElement;
    const targetPosition = itemSliderHomeForm.getBoundingClientRect().top;
    const btnTlf = document.querySelector('.btn-tlf') as HTMLElement;

    window.scrollTo({
      top: targetPosition,
      behavior: 'smooth',
    });

    itemSliderHomeForm.classList.toggle('opened');
    btnTlf.classList.toggle('active');
    const modalFilterForm = document.querySelector('.modal__filter-form') as HTMLElement;
    modalFilterForm.style.display = modalFilterForm.style.display == 'none' ? 'block' : 'none';
  }

  closeFilter(event: Event, Filtro: HTMLElement): void {
    
    event.preventDefault();
    /** TODO */
    $(Filtro).fadeToggle();
    /***/

    const itemSliderHomeForm = document.querySelector('.item__slider-home-form') as HTMLElement;
    const btnHeaderCita = document.querySelector('.btn-header-cita') as HTMLElement;
    const body = document.querySelector('body') as HTMLElement;
    itemSliderHomeForm.classList.toggle('opened');
    btnHeaderCita.classList.remove('opened');
    body.classList.remove('modal__open');
  }


  Togglefooter(event: Event, desplegableClick: HTMLAnchorElement): void {
    event.preventDefault();
    $(desplegableClick)
      .parents('.item__footer-heading')
      .siblings('.item__footer-colapsed')
      .slideToggle();
  }

}


